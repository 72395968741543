import useDirectorio from "../Context/useDirectorio";
import Card from "./Card";
import { useState } from "react";
import { useEffect } from "react";
import EmptyCard from "./EmptyCard";
const CardGrid = ({ data }) => {
  const { searchParameters } = useDirectorio();
  const [displayList, setDisplayList] = useState(data);

  const handleSearch = async () => {
    let lista = []
    //Filtrar unicamente por departamento
    if(searchParameters.departamento !== 0){
        lista = data.filter(empleado => empleado.departamento == searchParameters.departamento)
        //Filtrar por departamento y por nombre también
        if(searchParameters.text.length >0){
            const regex = new RegExp(searchParameters.text,"gi")
            lista = lista.filter(empleado => empleado.nombreCompleto.match(regex))
            return lista
        }
        return lista

    //Filtrar únicamente por nombre
    }else if(searchParameters.text.length>0){
        const regex = new RegExp(searchParameters.text,"gi")
        lista = data.filter(empleado => empleado.nombreCompleto.match(regex))
        return lista
    }
    //Sin filtros
    else{
        lista = data
        return lista
    }
    
  };
  useEffect(() => {
     handleSearch().then(msg => setDisplayList(msg))
  }, [searchParameters]);

  return (
    <section className="container">
      <div className="card-grid">
        {displayList.length !== 0 ?
        displayList.map((empleado) => (
          <Card key={empleado.id} empleado={empleado} />
        )) : <EmptyCard/>}
      </div>
    </section>
  );
};

export default CardGrid;
