const ModalidadHeader = ({ data }) => {
  return (
    <div className="modalidad-header">
      <div className="title-modalidad">
        <div className="title-container">
          <h2>Modalidad</h2>
          <h1>{data.Modalidad}</h1>
        </div>
        <div className="icon-container">
          <img src={data.Ilustracion_Blanco} alt="icono" />
        </div>
      </div>
    
    </div>
  );
};

export default ModalidadHeader;
