import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({setModal}) => {
  return (
    <div className="modal" onClick={setModal}>
      <div className="contenedor-principal">
        <div className="contenedor-titulo">
          <h2>Garantías</h2>
          <FontAwesomeIcon onClick={setModal} className="icono" icon={faXmarkCircle} />
        </div>
        
        <div className="contendor-texto">
          <p>
            <span>Garantias hipotecarias:</span> Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Minima numquam iusto ex consectetur?
            Mollitia blanditiis quidem laborum enim dolorum. Tempore officiis
            magni minus ipsum hic aperiam soluta numquam, nesciunt quia!
          </p>
          <p>
            <span>Garantias hipotecarias:</span> Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Minima numquam iusto ex consectetur?
            Mollitia blanditiis quidem laborum enim dolorum. Tempore officiis
            magni minus ipsum hic aperiam soluta numquam, nesciunt quia!
          </p>
          <p>
            <span>Garantias hipotecarias:</span> Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Minima numquam iusto ex consectetur?
            Mollitia blanditiis quidem laborum enim dolorum. Tempore officiis
            magni minus ipsum hic aperiam soluta numquam, nesciunt quia!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
