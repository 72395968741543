const ElementosGrid = () => {
  return (
    <>
    <div className='elementos-grid'>
      <div className='encabezado'>
        <div className='titulo'>Dirigido a</div>
        <div className='container'><p>
        Personas físicas y morales con actividad empresarial, estratificadas como microempresas.</p>
        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Monto</div>
        <div className='container'><p>Desde <span>$20,000 </span>hasta <span>$50,000.00 </span></p></div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Tipo de Crédito</div>
        <div className='doble-container'>
        
            <div className='container'><p>Capital de trabajo <span>(avío)</span></p></div>
            <div className='container'><p>Compra de activos fijos <span>(Refaccionario)</span></p></div>
          

        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Plazo</div>
        <div className='doble-container'>

            <div className='container'><p></p><p><span>Hasta 12 meses</span> para avio</p></div>
            <div className='container'><p></p><p><span>Hasta 36 meses</span> para refaccionario</p></div>
         

        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Tasa de Interés</div>
        <div className='container'><p><span>16%</span> anual fija</p></div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Garantías</div>
        <div className='container'><p><span>Aval</span></p></div>
      </div>
    </div>

    <h4 className="consideraciones">*Hasta 3 meses incluida en el plazo únicamente a capital.</h4>
    </>
  );
};

export default ElementosGrid;
