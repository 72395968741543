import ModalidadHeader from "../Reutilizables/ModalidadHeader";
import Data from "../../Modalidades.json";
import RequisitosCintillo from "../Reutilizables/RequisitosCintillo";
import RequisitosContainer from "../Reutilizables/RequisitosContainer";
import ElementosGrid from "./ElementosGrid";
import ElementosMobile from "./ElementosMobile";
const Verde = () => {
  return (
    <section className="modalidad">
      <div className="detalles">
        <img
          className="fondo"
          src="/Assets/modalidad/Background.png"
          alt="waves"
        />
        <ModalidadHeader data={Data.modalidades[5]} />
        <ElementosMobile />
        <ElementosGrid />
      </div>

      <RequisitosCintillo data={Data.modalidades[5]} />
      <RequisitosContainer data={Data.modalidades[5]} />
      <p
        style={{
          textAlign: "center",
          marginTop: "3rem",
          marginBottom: "2rem",
          fontWeight: 500,
        }}
      >
        *Todos los archivos se reciben únicamente por correo electrónico
      </p>
    </section>
  );
};

export default Verde;
