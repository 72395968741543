import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationPin,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
const Sucursal = ({ data }) => {
  return (
    <div className="sucursal" >
      <h3>{data.sucursal}</h3>
      <div className="set">
        <div className="icon-container">
          <FontAwesomeIcon className="icon" icon={faLocationPin} />
          <p>{data.direccion}</p>
        </div>
        <div className="icon-container">
          <FontAwesomeIcon className="icon" icon={faPhone} />
          <p>{data.telefono}</p>
        </div>
      </div>

      <div className="sub-set">
      <h4>Departamento Promoción</h4>
        {data.promocion.map(promotor => (
              
                    <div key={promotor.id}>
                    <div className="icon-container">
                      <FontAwesomeIcon className="icon" icon={faPhone} />
                      <p>{promotor.telefono}</p>
                    </div>{" "}
                    <div className="icon-container">
                      <FontAwesomeIcon className="icon" icon={faEnvelope} />
                      <p>{promotor.email}</p>
                    </div>
                    </div>
              
        ))}

      </div>
    </div>
  );
};

export default Sucursal;
