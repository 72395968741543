//Hecho por Ricardo Corral Sanchez. Github: @ricardocos
import { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import TransparenciaDocs from "../TransparenciaDocs/TransparenciaDocs";

const Error404 = () => {
  

  return (
    <div className="contenedor-error">
      <h1>404</h1>
      <h2>Lo sentimos, esta página no existe.</h2>
      <img src={require("../PaginaError/imagenError.png")} alt="Imagen Error" />
      <Link style={{textDecoration: "none"}} to={"/"}>
        <h3>Ir al sitio principal de  Fideapech</h3>
      </Link>
    </div>
  );
};

export default Error404;
