import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Modalidad = ({ modalidad,clase }) => {
  const navigate = useNavigate()
    
  return (
    <div onClick={() => navigate(modalidad.ruta)} className={modalidad.idModalidad === "5" ? "verde modalidad" : "modalidad"} >
  
        <img src={modalidad.Ilustracion_Blanco} alt='modalidad' />
        <h2>{modalidad.Modalidad}</h2>
        <p>{modalidad.Frase} </p>
        {/*<Button className="boton" onClick={() => navigate(modalidad.ruta)}  variant="contained" color="secondary" style={{marginTop:10}}>Conoce más</Button>*/}
      
    </div>
  );
};

export default Modalidad;
