import {
  faCalendarDays,
  faHandHoldingDollar,
  faMoneyCheck,
  faPercent,
  faUser,
  faAward
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ElementosMobile = ({ data }) => {
  return (
    <section className="elementos-celular">
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faUser} />

        <div className="contenedor-texto">
          <h2>Sujeto de Apoyo</h2>
          <p>
            Micro, pequeñas y medianas empresas que tengan un proyecto de
            innovación, que sea un sector estratégico, que demuestre inversión
            privada de terceros o rondas de inversión.
          </p>
        </div>
      </div>

      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faHandHoldingDollar} />

        <div className="contenedor-texto">
          <h2>Monto</h2>
          <p>Hasta $1'000,000.00</p>
        </div>
      </div>

      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faCalendarDays} />

        <div className="contenedor-texto">
          <h2>Nota convertible</h2>
          <p>3 años con posibilidad de extensión a 5 años.</p>
        </div>
      </div>
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faPercent} />

        <div className="contenedor-texto">
          <h2>Tasa</h2>
          <p>12% anual</p>
        </div>
      </div>
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faAward} />

        <div className="contenedor-texto">
          <h2>Garantías</h2>
          <p>No aplica</p>
        </div>
      </div>
    </section>
  );
};

export default ElementosMobile;
