import ConceptosContainer from './ConceptosContainer/ConceptosContainer';
import PreguntasContainer from './PreguntasContainer/PreguntasContainer';
import Data from '../DataFAQ.json';
import { FaqProvider } from '../Context/FaqContext';
import Contenido from "../../../Contenido.json"
const MainContainer = () => {

  return (
    <section className='main-container'>
      <FaqProvider>
        {Contenido.FAQ.conceptos.map(concepto => (
          <PreguntasContainer key={concepto.id} data={concepto} />
        ))}
        
      </FaqProvider>
    </section>
  );
};

export default MainContainer;
