import Landing from "./Landing/Landing";
import ModalidadesGrid from "./ModalidadesGrid/ModalidadesGrid";
import Data from "./DataIndex.json";
import { useState, useEffect } from "react";
import ComoFunciona from "./ComoFunciona/ComoFunciona";
import BottomWaves from "./BottomWaves/BottomWaves";
import Testimonios from "./Testimonios/Testimonios";
import PorqueNosotros from "./PorqueNosotros/PorqueNosotros";
import Clientes from "./Clientes/Clientes";
import FideBot from "../../Fidebot/Fidebot";
//import PopUpNotificaciones from "../PopUp/PopUp";

const Index = () => {
 
  return (
    <section className="index">
      

      <Landing  />
      <ModalidadesGrid />

      {/*<Clientes/>*/}
    
      <PorqueNosotros/>
      <ComoFunciona/>
 
      
    
    </section>
  );
};

export default Index;
