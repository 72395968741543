const ElementosGrid = () => {
  return (
    <>
      <div className="elementos-grid">
        <div className="encabezado">
          <div className="titulo">Sujeto de Apoyo</div>
          <div className="container">
            Concesionarios de servicio público de transporte de taxi, que
            quieran adquirir una nueva unidad de taxi.
          </div>
        </div>
        <div className="encabezado">
          <div className="titulo">Monto</div>
          <div className="container">Hasta $230,000.00</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Tipo de Crédito</div>
          <div className="container">Unidad Nueva</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Plazo</div>
          <div className="container">Hasta 60 meses</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Tasa</div>
          <div className="container">10% Anual Fija</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Garantías</div>
          <div className="container">
            Factura del vehículo adquirido y aval.
          </div>
        </div>
      </div>
    </>
  );
};

export default ElementosGrid;
