
//import PopUpNotificaciones from "../PopUp/PopUp";
import MisionContainer from "./Componentes/MisionContainer";
import Slider from "./Componentes/Slider";
import Testimonios from "./Componentes/Testimonios";

const QuienesSomos = () => {
    return ( 
        
        <section className="quienes-somos">
            
            <img className="fondo" src="/assets/backgrounds/quienes-somos.png" alt="fondo" />
            
            <Slider/>
            <MisionContainer/>

        </section>
     );
}
 
export default QuienesSomos;