
import { Button, TextField } from "@mui/material";
import Contenido from "../../../Contenido.json";
import TelefonoContainer from "./TelefonoContainer";
import Swal from "sweetalert2";
const FormContainer = () => {
  return (
    <div className="contact-form">
      <h1>Contáctanos</h1>
      <p>
      Deja tus datos y envía. En corto tiempo te contactaremos.
      </p>

      <form style={{ padding: "0px 30px" }}>
        <TextField required fullWidth label="Nombre" />
        <TextField required fullWidth label="Email" />
        <TextField required fullWidth label="Teléfono" />
        <TextField required fullWidth label="¿Como te enteraste de nosotros?" />
        <Button onClick={() => Swal.fire("Lo sentimos","Esta función está deshabilitada temporalmente, contactanos a través de facebook","info")} color="error" variant="contained">
          Enviar
        </Button >
      </form>
      <div className="telefonos-container">
       {Contenido.footer.sucursales.map(sucursal => (
        <TelefonoContainer sucursal={sucursal} />
       ))}
        
      </div>
    </div>
  );
};

export default FormContainer;
