
//import PopUpNotificaciones from "../PopUp/PopUp";
import Licitacion from "./Licitacion";
import Data from "./LicitacionesTotales.json";

const Licitaciones = () => {
  return (
    <main className="licitaciones">
      
      <div className="title-container">
        <h1>Listado de Licitaciones</h1>
      </div>
      
      <div className="contenedor-licitaciones">
        {Data.licitaciones.map((licitacion) => (
          <Licitacion licitacion={licitacion} />
        ))}
      </div>
    </main>
  );
};

export default Licitaciones;
