import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import useFaq from "../../Context/useFaq";
import Pregunta from "./Pregunta";
import Preguntas from "./Preguntas";

const PreguntasContainer = ({data}) => {
    const [show, setShow] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [clase, setClase] = useState("preguntas-load preguntas")
    const activarAnimacion = ( ) => {
        if(show) {
            setShow(!show)
            setClase("preguntas-hide preguntas")
        }else{
            setShow(!show)
            setClase("preguntas-show preguntas")
        }
    }

    
    return ( 
        <div className="preguntas-container">
            <div onClick={() =>activarAnimacion()} className="title-pregunta">
               <div className="icono">
                <FontAwesomeIcon className="font-icon" icon={data.Ilustracion}></FontAwesomeIcon>
               </div>
                <div className="texto">
                <div className="titulo">
                    <h1>{data.Concepto}</h1>
                </div>
                <div className="subtitulo">
                    <p >{data.Descripcion}</p>
                </div>
                </div>

            </div>

          <Preguntas data={data} clase={clase}/>
        </div>
     );
}
 
export default PreguntasContainer;