import { faCalendarDays, faHandHoldingDollar, faMoneyCheck, faPercent, faUser, faAward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ElementosMobile = ({ data }) => {
  return (
    <section className="elementos-celular">
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faUser} />

        <div className="contenedor-texto">
          <h2>Sujeto de Apoyo</h2>
          <p>Autoempleados, micro, pequeñas y medianas empresas con al menos  1 año registradas ante el SAT.</p>
        </div>
      </div>

      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faHandHoldingDollar} />

        <div className="contenedor-texto">
          <h2>Monto</h2>
          <p>Hasta $5'000,000.00</p>
        </div>
      </div>
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faCalendarDays} />

        <div className="contenedor-texto">
          <h2>Capital de Trabajo (avío)</h2>
          <p>Hasta 36 meses</p>
        </div>
      </div>
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faCalendarDays} />

        <div className="contenedor-texto">
          <h2>Compra de Activos Fijos</h2>
          <p>Hasta 60 meses</p>
        </div>
      </div>
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faPercent} />

        <div className="contenedor-texto">
          <h2>Tasa</h2>
          <p>12% anual</p>
        </div>
      </div>
      <div className="contenedor">
        <FontAwesomeIcon className="icono" icon={faAward} />

        <div className="contenedor-texto">
          <h2>Garantías</h2>
          <p>Prendaria, Hipotecaria</p>
        </div>
      </div>
    </section>
  );
};

export default ElementosMobile;
