// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faTrashAlt,
  faHandHoldingDollar,
  faPercent,
  faMoneyCheckDollar,
  faSackDollar,
  faCommentsDollar,
  faPhoneVolume,
  faCircleQuestion,
  faEnvelopeCircleCheck,
  faMagnifyingGlassChart,
  faListCheck,
  faMoneyBillTransfer,
  faFilePen
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faTrashAlt,
  faHandHoldingDollar,
  faPercent,
  faMoneyCheckDollar,
  faCommentsDollar,
  faSackDollar,
  faPhoneVolume,
  faCircleQuestion,
  faEnvelopeCircleCheck,
  faMagnifyingGlassChart,
  faListCheck,
  faMoneyBillTransfer,
  faFilePen

  // more icons go here
);
