import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const TelefonoContainer = ({sucursal}) => {
    const llamar = () => {
        window.open(`tel:+52${sucursal.telefono}`,"_blank")
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
      }
    return ( 
        <div onClick={llamar} className="telefono">
          <FontAwesomeIcon className="icon" icon={faPhone} />
          <div className="textos">
            <h3>Teléfono {sucursal.sucursal}</h3>
            <p>{formatPhoneNumber(sucursal.telefono)}</p>
          </div>
        </div>
     );
}
 
export default TelefonoContainer;