import Slide from "./Componentes/Slide";
import SlideShow from "./SlideShow";
import Contenido from "../../../Contenido.json";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const images = [
    "Assets/Landing/administracion.webp",
    "Assets/Landing/administratio_2.webp",
    "Assets/Landing/analisis.webp",
    "Assets/Landing/direccion.webp",
    "Assets/Landing/jefes.webp",
    "Assets/Landing/planeacion.webp",
    "Assets/Landing/promocion.webp",
    "Assets/Landing/promocion_2.webp",
    "Assets/Landing/juridico-09.webp",
    "Assets/Landing/cobranza-10.webp",
  ];
  return (
    <section className="landing">
      <div className="contenedor-textos">
        <FontAwesomeIcon icon={faPiggyBank} className="icono" />
        <h1>
          El préstamo que tu empresa <span>necesita.</span>
        </h1>
        <h2>Tenemos la mejor tasa de interés del mercado.</h2>
        <Button
          type="button"
          variant="contained"
          className="boton-info"
          style={{ backgroundColor: "#ec1c72", color: "white" }}
          onClick={() =>
            window.open("https://forms.office.com/r/6T6EiLCEv6", "_blank")
          }
        >
          ¿Cómo aplicar?
        </Button>
      </div>
      <div className="contenedor-imagen">
        <img
          src={`${process.env.REACT_APP_DIRECTORIO}/${
            images[Math.floor(Math.random() * images.length)]
          }`}
          alt="fideapech-landing"
        />
        <img
          className="responsive"
          src="/Assets/Landing/landing-responsive.png"
          alt="fideapech-landing"
        />
      </div>
    </section>
  );
};

export default Landing;
