
//import PopUpNotificaciones from '../PopUp/PopUp';
import FormContainer from './Componentes/FormContainer';
import MapContainer from './Componentes/MapContainer';


const Contacto = () => {

  return (
    <section className='contacto'>
      
      <div className='background'></div>
      <div style={{paddingTop:100}} className='container'>
       <FormContainer/>
      <MapContainer/>
      </div>
    </section>
  );
};

export default Contacto;
