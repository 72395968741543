import { Button } from '@mui/material';
import Contenido from "../../../Contenido.json"
const Slider = () => {
  return (
    <div className='slider'>
      <div className='slide'>
        <div className='background'></div>
        <div className='left-container'>
          
          <h2>{Contenido.quienesSomos.tiutlo}</h2>
          <p>
            {Contenido.quienesSomos.subtitulo}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Slider;
