import { useNavigate } from "react-router-dom";

const Modalidad = ({modalidad}) => {
    const navigate = useNavigate()
    function formatearNumeroComoDivisa(numero) {
        // Asegurarse de que el número es un valor numérico
        if (isNaN(numero)) {
            return "Error: ¡No es un número válido!";
        }
    
        // Redondear el número a dos decimales
        numero = parseFloat(numero).toFixed(2);
    
        // Separar la parte entera de la decimal
        var partes = numero.toString().split(".");
        var parteEntera = partes[0];
        var parteDecimal = partes.length > 1 ? "." + partes[1] : "";
    
        // Agregar el separador de miles
        parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
        // Formatear la divisa
        var resultado = parteEntera + parteDecimal;
        if(resultado.length > 10){
            //Reemplazar la primera coma por un apostrofe
            resultado = resultado.replace(",", "'",1);
        }
        return `$${resultado}`;
    }
    return ( 
        <div onClick={() => navigate(modalidad.ruta)} className="modalidad">
                <div className="contenedor-icono">
                    <img src={modalidad.Ilustracion_Blanco} alt={modalidad.Modalidad} />
                </div>

                <h3>{modalidad.Modalidad}</h3>
                <p>{modalidad.Frase}</p>
                <h4>Hasta {formatearNumeroComoDivisa(modalidad.Monto_Max)}</h4>
               
            </div>
     );
}
 
export default Modalidad;