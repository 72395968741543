import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Etapa = ({etapa}) => {
  return (
    <div className="elemento">
      <div className="linea-container">
        <div className="linea"></div>
      </div>
      <div className="numero">{etapa.etapa}</div>
    
      <div className="icono-contenedor">
        <FontAwesomeIcon className="icono" icon={etapa.icono}/>
      </div>
      <div className="titulo">
        <h3>{etapa.titulo}</h3>
      </div>
      <div className="descripcion">
        
        <p>
         {etapa.descripcion}
        </p>
      </div>
    </div>
  );
};

export default Etapa;

