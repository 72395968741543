import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useState } from "react";
import useRequest from "../../Hooks/useRequest";
import { useEffect } from "react";
import {Markup } from 'interweave';

const PopUpNotificaciones = () => {
  const peticionObtenerPopUp = useRequest("/api/popUp");
  const [popUp, setPopUp] = useState(false);
  const [mostar, setMostar] = useState(false);
  const [imagen, setImagen] = useState(false);


  const obtenerPopUp = async () => {
    const res = await peticionObtenerPopUp.get();
    if (res.status === 200) {
      //console.log(res.data.Imagen)
      setMostar(true);
      setPopUp(res.data);
      if(res.data.Imagen != undefined)
      {
        
        setImagen(`data:image/png;base64,${res.data.Imagen}`);
      }
    }
    else if(res.status == 404)
    {
      //console.log("No hay pop up");
      }
  };
  useEffect(() => {
    obtenerPopUp();
  }, []);

   
    if (!mostar) return null
    if (peticionObtenerPopUp.cargando) return null;
    if (!popUp) return null;
  return (
    <>
      {popUp ? (
  <div className="notificaciones">
    <div className="pop-up">
      <h2 className="titulo">
        <FontAwesomeIcon className="icono" icon={faWarning} />
        <span>{popUp.Titulo}</span>
      </h2>
      <Markup content={popUp.Descripcion} />
      <h3>fideapech</h3>
      {imagen && <img className="imagen-pop-up" src={imagen} alt="imagen" />}
      <Button onClick={() => setMostar(false)} variant="contained">Cerrar</Button>
    </div>
  </div>
) : null}

    </>
  );
};

export default PopUpNotificaciones;
