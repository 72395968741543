import { useEffect, useRef, useState } from "react";

const Beneficio = ({beneficio,y}) => {
    const beneficioRef = useRef(null);


    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
    if(y + document.documentElement.clientHeight > beneficioRef.current.offsetTop){
        //console.log(beneficioRef.current)   
        beneficioRef.current.firstChild.style.transform = "scale(1.1)";
        
        beneficioRef.current.firstChild.style.transition = `transform ${1+ beneficio.idElemento /1.5}s ease`;

    }
    else if(y + document.documentElement.clientHeight < beneficioRef.current.offsetTop){
        beneficioRef.current.firstChild.style.transform = "scale(.8)";
        beneficioRef.current.firstChild.style.transition = "transform 0.5s ease";
    }
     ////console.log(y + document.documentElement.clientHeight, beneficioRef.current.offsetTop)
    }, [y])
    return ( 
        <div ref={beneficioRef}  className="beneficio">
        <img src={beneficio.ilustracion} alt="beneficio" />
        <p>{beneficio.titulo}</p>
    </div>
     );
}
 
export default Beneficio;