import { Button } from "@mui/material";
import Swal from "sweetalert2";

const RequisitosCintillo = ({data}) => {
    const bloquearDescarga = () => {
        if(false){
            Swal.fire("Lo sentimos", "La descarga de archivos no está disponible en este momento", "info")
        }
        else{
            window.open(data.ArchivoDescarga)
        }
    }
    return ( 
        <div className="requisitos-cintillo">
            
            <div className="contenedor-cintillo">
            <h2><span>Requisitos</span> para obtener tu préstamo</h2>
            <h4>Puedes selecciónar cada uno de ellos para obtener más información</h4>
            </div>
            <Button enabled onClick={() =>bloquearDescarga()} variant="contained" className="btn-cintillo">Descargar Paquete de Archivos</Button>
        </div>
     );
}
 
export default RequisitosCintillo;