//Hecho por Ricardo Corral Sanchez. Github: @ricardocos

import { useRef, useState , useEffect} from "react";

export const Subasta = ({ subasta }) => {
  const [detalles, setDetallas] = useState(false);
  const mostrarRef = useRef(null)

  function mostrarDetalles() {
    if (detalles) {
      mostrarRef.current.style.maxHeight = "500px";
    } else {
      mostrarRef.current.style.maxHeight = "0px"
    }
  }

  useEffect(() => {
    mostrarDetalles();
  }, [detalles]);

  return (
    <div className="contenedor-subasta">
      <div onClick={() => setDetallas(!detalles)} className="titulo-subasta">
        <h2>{subasta.subasta}</h2>
        <h2>Ver Documentos</h2>
      </div>

      <div className="subasta" ref={mostrarRef}>
        <h3 className="elemento descripcion">{subasta.descripcion}</h3>
        {subasta.elementos.map((elemento) => (
          <section className="elemento documento">
            <h4>{elemento.titulo}</h4>
            <button onClick={() => window.open(elemento.link)}>
              Descargar
            </button>
          </section>
        ))}
      </div>
    </div>
  );
};

export default Subasta;
