import { useNavigate } from "react-router-dom";
import ListaEmisiones from "./ListaEmisiones";

const BienvenidaEmisiones = () => {
  const navigate = useNavigate();

    return ( 
        <div className="bienvenida">
        <img src="/Assets/emisiones/pinos.png" alt="pinos" />
        <h3>Descubre el impacto ambiental de tus actividades</h3>
        <p>
          Basada en la calculadora de emisiones del RENE (Registro Nacional de
          Emisiones)
        </p>
        <button onClick={() =>navigate("AgregarEmision")}>Comenzar</button>
      </div>
     );
}
 
export default BienvenidaEmisiones;