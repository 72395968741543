import axios from "axios";
import { useEffect, useState } from "react";
import useRequestS3 from "../../Hooks/useRequestS3";

import { Button } from "@mui/material";
import FileSaver from "file-saver";

const TransparenciaDocs = () => {
  const [existe, setExiste] = useState(false);
  const peticionArchivo = useRequestS3(window.location.pathname);
  const consultarExistencia = async () => {
    //console.log(window.location.pathname);
    const response = await peticionArchivo.get(null, "blob");

    if (response.status === 200) {
      
      setExiste(response.data);
    }
  };
  const descargarArchivo = () => {
    FileSaver.saveAs(
      existe,
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ]
    );
  };
  useEffect(() => {
    consultarExistencia();
  }, []);

  if (peticionArchivo.cargando) {
    return (
      <section className="transparencia-docs">
        <div className="contenedor">
          <p>Buscando archivo...</p>
        </div>
      </section>
    );
  }

  if (!existe) {
    return (
      <section className="transparencia-docs">
        <div className="contenedor">
          <h2>
            Bienvenido al portal de transparencia y consulta de archivos de
            Fideapech
          </h2>
          <p>
            Estás buscando el archivo:{" "}
            <span>
              {
                window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ]
              }
            </span>
          </p>

          <img src="/Assets/Reutilizables/doc-not-found.png" alt="" />

          <p>Lo sentimos, no encontramos el archivo que buscas</p>
        </div>
      </section>
    );
  }
  return (
    <section className="transparencia-docs">
      <div className="contenedor">
        <h2>
          Bienvenido al portal de transparencia y consulta de archivos de
          Fideapech
        </h2>
        <p>
          Estás buscando el archivo:{" "}
          <span>
            {
              window.location.pathname.split("/")[
                window.location.pathname.split("/").length - 1
              ]
            }
          </span>
        </p>

        <img src="/Assets/Reutilizables/doc-found.png" alt="" />
        <Button onClick={descargarArchivo} variant="contained">Descargalo aquí</Button>
      </div>
    </section>
  );
};

export default TransparenciaDocs;
