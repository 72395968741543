import Modalidad from "./Modalidad";
import ModalidadesData from "../../Modalidades.json"
//import PopUpNotificaciones from "../PopUp/PopUp";
const Modalidades = () => {
    return ( 
        <section className="modalidades">
          
          <div className="contenedor-titulo">
            <h1>Modalidades</h1>
            <p>Elige la opción que mejor se adapte a tus necesidades de financiamento</p>
          </div>

          <div className="modadlidades-grid-page">
            {ModalidadesData.modalidades.map(modalidad=>(
                <Modalidad modalidad={modalidad}/>
            ))}

          </div>
        </section>
     );
}
 
export default Modalidades;