import Etapa from "./Etapa";
import Contenido from "../../../Contenido.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ComoFunciona = () => {
  return (
    <section className="como-funciona">
      <h1>¿Cómo conseguir tu préstamo?</h1>

      <div className="elementos-container">
        {Contenido.index.comoFunciona.map((etapa) => (
          <Etapa etapa={etapa} />
        ))}
      </div>
    </section>
  );
};

export default ComoFunciona;
