const ElementosGrid = () => {
  return (
    <>
      <div className="elementos-grid">
        <div className="encabezado">
          <div className="titulo">Sujeto de Apoyo</div>
          <div className="container">
            Autoempleados, micro, pequeñas y medianas empresas con al menos 1
            año registradas ante el SAT.
          </div>
        </div>
        <div className="encabezado">
          <div className="titulo">Monto</div>
          <div className="container">Hasta $2'000,000.00</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Tipo de Crédito</div>
          <div className="container">
            Compra de activos fijos (Refaccionario)
          </div>
        </div>
        <div className="encabezado">
          <div className="titulo">Plazo</div>

          <div className="container">Hasta 60 meses</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Tasa</div>
          <div className="container">10% anual fija</div>
        </div>
        <div className="encabezado">
          <div className="titulo">Garantías</div>
          <div className="container">Prendaria, Hipotecaria</div>
        </div>
      </div>

      <h4 className="consideraciones">
        *Hasta 6 meses de gracia sobre capital en créditos refaccionarios y 3
        meses en habilitación y avío
      </h4>
    </>
  );
};

export default ElementosGrid;
