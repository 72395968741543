//Hecho por Ricardo Corral Sanchez. Github: @ricardocos

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TransparenciaProactiva = () => {
  const navigate = useNavigate();
  return (
    <section className="transparencia-proactiva">
      <div className="contenedor-transparencia">
        <h1>Transparencia Proactiva</h1>

        <div className="opciones-transparencia">
          <Button variant="contained" onClick={() => navigate("/Licitaciones")}>Licitaciones</Button>
          <Button variant="contained" onClick={() => navigate("/Subastas")}>Subastas</Button>
          <Button variant="contained" onClick={() => navigate("/Organigrama")}>Organigrama</Button>
        </div>
      </div>
    </section>
  );
};

export default TransparenciaProactiva;
