const Card = ({ empleado }) => {
  //console.log(`${process.env.REACT_APP_DIRECTORIO}${empleado.fotografia}`);
  return (
    <div className="card">
      <img src={`${process.env.REACT_APP_DIRECTORIO}/${empleado.fotografia}`} alt="profile" />
      <div className="contenedor-texto">
        <h3>{empleado.puesto}</h3>
        <h2>{empleado.nombre}</h2>
        <p>{empleado.email}</p>
        <h5>
          {empleado.telefono} Ext. {empleado.ext}
        </h5>
      </div>
    </div>
  );
};

export default Card;
