import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Emisiones = () => {
  useEffect(() => {
    let header = document.querySelector("header");
    header.classList.add("header-emisiones");
  }, []);
  return (
    <section className="emisiones">
      <h2>
        <span>Calculadora </span>
        <span>de </span>
        <span>Emisiónes </span>
        <span>de </span>
        <span>Carbono. </span>
      </h2>
    
    <Outlet/>
    </section>
  );
};

export default Emisiones;
