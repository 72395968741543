import Modalidad from "./Componentes/Modalidad";
import Data from "../../../Modalidades.json";
import { useEffect, useState } from "react";
const ModalidadesGrid = () => {
  return (
    <section className="modalidades-grid">
      <div className="contenedor-textos">
        <h2>
          <span>Fondo </span>
          <span>del </span>
          <span>Estado </span>
          <span>de </span>
          <span>Chihuahua. </span>
        </h2>
        <p>
          Financiamiento para  emprendedores, micro,
          pequeñas y medianas empresas del estado de Chihuahua. <span>Conoce las diferentes modalidades de
          préstamo.</span>
        </p>
      </div>

      <div className="contenedor-modalidades">
        {Data.modalidades.map((modalidad, index) => (
          <Modalidad
            key={index}
            modalidad={modalidad}
            clase={
              index % 2 === 0 ? "modalidad-izquierda" : "modalidad-derecha"
            }
          />
        ))}
      </div>
    </section>
  );
};

export default ModalidadesGrid;
