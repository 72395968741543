import { createContext, useState } from "react";

const DirectorioContext = createContext();

const DirectorioProvider = ({ children }) => {
  const [searchText, setSearchText] = useState();
  const [searchDepartment, setSearchDepartment] = useState(0);
  const [searchParameters, setSearchParameters] = useState({
    text: "",
    departamento: 0,
  });
  return (
    <DirectorioContext.Provider
      value={{
        searchText,
        setSearchText,
        searchDepartment,
        setSearchDepartment,searchParameters, setSearchParameters
      }}
    >
      {children}
    </DirectorioContext.Provider>
  );
};

export { DirectorioProvider };

export default DirectorioContext;
