import Beneficio from "./Beneficio";
import Contenido from "../../../Contenido.json";
import { useEffect, useRef, useState } from "react";
const PorqueNosotros = () => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [backgorundColor, setBackgorundColor] = useState(true);
  const tituloRef = useRef(null);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  const cambiarColorNavBar = () => {
    let yPos = window.scrollY;
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      cambiarColorNavBar();
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);
  return (
    <section className="porque-nosotros">
      <h2 ref={tituloRef}>
        ¿Por qué somos <span>tu mejor opción</span>?
      </h2>

      <div className="beneficios">
        {Contenido.index.beneficiosFideapech.map((beneficio) => (
          <Beneficio beneficio={beneficio} y={lastScrollY} />
        ))}
      </div>
    </section>
  );
};

export default PorqueNosotros;
