import Data from "../DataContacto.json"
import { Map,Marker } from "pigeon-maps";
const MapContainer = () => {
  return (
    <div className="map-container">
    
      <div className="sucursal-container">
        <h2>Chihuahua</h2>
        <Map
          className="mapa"
          height={"95%"}
          center={[Data.sucursales[0].latitud, Data.sucursales[0].longitud]}
          zoom={17}
          animate
        >
          <Marker onClick={() => window.open("https://www.google.com.mx/maps/place/FIDEAPECH/@28.6267924,-106.1206352,20.33z/data=!4m6!3m5!1s0x86ea41897d5b16e7:0x8374602d1fd0e2dc!8m2!3d28.626801!4d-106.1206623!16s%2Fg%2F11g8wc_v98","_blank")} width={50} anchor={[Data.sucursales[0].latitud, Data.sucursales[0].longitud]} />
        </Map>
      </div>
      <div className="sucursal-container">
        <h2>Cd. Juárez</h2>
        <Map
          className="mapa"
          height={"95%"}
          center={[Data.sucursales[1].latitud, Data.sucursales[1].longitud]}
          zoom={17}
          animate
        >
          <Marker onClick={() => window.open("https://www.google.com.mx/maps/place/Unidad+Administrativa+del+Gobierno+del+Estado+de+Chihuahua+Jos%C3%A9+Mar%C3%ADa+Morelos+y+Pav%C3%B3n/@31.7501227,-106.4573045,16z/data=!4m10!1m2!2m1!1sgobierno+del+estado+de+chihuahua!3m6!1s0x86e75967ff8512e7:0x75783f9e126829f4!8m2!3d31.7501227!4d-106.4523911!15sCiBnb2JpZXJubyBkZWwgZXN0YWRvIGRlIGNoaWh1YWh1YZIBGmRpc3RyaWN0X2dvdmVybm1lbnRfb2ZmaWNl4AEA!16s%2Fg%2F1tmpkz2_","_blank")}  width={50} anchor={[Data.sucursales[1].latitud, Data.sucursales[1].longitud]} />
        </Map>
      </div>
    </div>
  );
};

export default MapContainer;
