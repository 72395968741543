const ElementosGrid = () => {
  return (
    <>
    <div className='elementos-grid'>
      <div className='encabezado'>
        <div className='titulo'>Dirigido a</div>
        <div className='container'><p>
        Emprendedores o empresas con antigüedad menor a 12 meses registrados ante el SAT.</p>
        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Monto</div>
        <div className='container'><p>Desde <span>$25,000 </span>hasta <span>$250,000.00 </span></p></div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Tipo de Crédito</div>
        <div className='doble-container'>
        
            <div className='container'><p>Capital de trabajo <span>(avío)</span></p></div>
            <div className='container'><p>Compra de activos fijos <span>(Refaccionario)</span></p></div>
          

        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Plazo</div>
        <div className='doble-container'>

            <div className='container'><p></p><p><span>Hasta 18 meses</span> para capital de trabajo</p></div>
            <div className='container'><p></p><p><span>Hasta 48 meses</span> para activo fijo</p></div>
         

        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Tasa de Interés</div>
        <div className='container'><p><span>12%</span> anual fija</p></div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Garantías</div>
        <div className='container'><p><span>Prendaria</span> ó <span>Hipotecaria</span></p></div>
      </div>
    </div>

    <h4 className="consideraciones">*Hasta 6 meses de gracia sobre capital en créditos refaccionarios y 3 meses en habilitación y avío</h4>
    </>
  );
};

export default ElementosGrid;
