import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useDirectorio from "../Context/useDirectorio";

const Buscador = () => {
  const {searchParameters,setSearchParameters} = useDirectorio()
  const handleText = (e) => {
    setSearchParameters(
      {...searchParameters,
      [e.target.name]:e.target.value}
    )
  }
  return (
    <div className="search-options">
      <div className="input-container">
       {/*} <div className="icon-container">
          <FontAwesomeIcon className="icono" icon={faMagnifyingGlass} />
  </div>*/}

        <TextField
          fullWidth
          label="Buscar"
          color="secondary"
          variant="outlined"
          name="text"
          onChange={e => handleText(e)}
        />
      </div>

      <FormControl
      className="select"
        variant="outlined"
        color="secondary"
        sx={{ m: 1, minWidth: 200 }}
      >
        <InputLabel id="demo-simple-select-filled-label">
          Departamento
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          defaultValue={""}
          onChange={e => handleText(e)}
          name="departamento"
        >
          <MenuItem value={0}>
            <em  >Todos</em>
          </MenuItem>
          <MenuItem value={4}>Análisis de Riesgo</MenuItem>
          <MenuItem value={3}>Promoción</MenuItem>
          <MenuItem value={1}>Administrativo</MenuItem>
          <MenuItem value={2}>Jurídico</MenuItem>
          <MenuItem value={5}>Dirección General</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Buscador;
