import Pregunta from "./Pregunta";
const Preguntas = ({data,clase}) => {
  return (
    <div  className={clase}>
      {data.preguntas.map((pregunta) => (
        <Pregunta key={pregunta.idPregunta} data={pregunta} />
      ))}
    </div>
  );
};

export default Preguntas;
