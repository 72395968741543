import Modal from "./Componentes/Modal";
import "./Styles/index.css";

import { ThemeProvider, createTheme } from "@mui/material";
import Structure from "./Paginas/Structure/Structure";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Index from "./Paginas/Index/Index";
import Directorio from "./Paginas/Directorio/Directorio";
import FAQ from "./Paginas/FAQ/FAQ";
import Contacto from "./Paginas/Contacto/Contacto";
import Modalidad from "./Paginas/Modalidad/Modalidad";
import Data from "./DataModalidades.json";
import QuienesSomos from "./Paginas/QuienesSomos/QuienesSomos";
import Modalidades from "./Paginas/Modalidades/Modalidades";
import Contenido from "./Modalidades.json";
import Admin from "./Admin/Admin";
import AdminLanding from "./Admin/Paginas/Landing/AdminLanding";
import AdminStructure from "./Admin/AdminStructure";
import Emprende from "./Modalidades/Emprende/Emprende";
import Empresas from "./Modalidades/Empresas/Empresas";
import TecnologiaAplicada from "./Modalidades/Startup/TecnologiaAplicada";
import Microcredito from "./Modalidades/Microcredito/Microcredito";
import Startup from "./Modalidades/TecnologiaAplicada/Startup";
import Franquicias from "./Modalidades/Franquicias/Franquicias";
import Verde from "./Modalidades/Verde/Verde";
import Taxi from "./Modalidades/Taxi/Taxi";
import ScrollToTop from "./ScrollToTop";
import Licitaciones from "./Paginas/Licitaciones/Licitaciones";
import Subastas from "./Paginas/Subastas/Subastas";
import Organigrama from "./Paginas/Organigrama/Organigrama";
import Error404 from "./Paginas/PaginaError/Error404";
import TransparenciaProactiva from "./Paginas/TransparenciaProactiva/TransparenciaProactiva"
import CalculadoraEmisiones from "./Paginas/CalculadoraEmisiones/CalculadoraEmisiones";
import Emisiones from "./Paginas/CalculadoraEmisiones/Emisiones";
import BienvenidaEmisiones from "./Paginas/CalculadoraEmisiones/BienvenidaEmisiones";
import TransparenciaDocs from "./Paginas/TransparenciaDocs/TransparenciaDocs";
const theme = createTheme({
  palette: {
    primary: {
      light: "#CE0F69",
      main: "#706F6F",
      dark: "#4f4f4f",
    },
    secondary: {
      light: "#CE0F69",
      main: "#FFFFFF",
      dark: "#4f4f4f",
    },
    success: {
      light: "#8dd8cf",
      main: "#706F6F",
      dark: "#4f4f4f",
    },
    warning: {
      light: "#8fa1ea",
      main: "#706F6F",
      dark: "#4f4f4f",
    },
    error: {
      light: "#dd5089",
      main: "#706F6F",
      dark: "#4f4f4f",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
          
            <Route ScrollToTop path="/" element={<Structure />}>
              <Route index element={<Index />} />
              <Route ScrollToTop path="Licitaciones" element={<Licitaciones />}/>
              <Route ScrollToTop path="Subastas" element={<Subastas />} />
              <Route ScrollToTop path="Organigrama" element={<Organigrama />} />
              <Route ScrollToTop path="TransparenciaProactiva" element={<TransparenciaProactiva/>}/>
              <Route ScrollToTop path="Directorio" element={<Directorio />} />
              <Route ScrollToTop path="TransparenciaDocs/*" element={<TransparenciaDocs />} />
              <Route ScrollToTop path="Modalidades" element={<Modalidades />} />
              <Route ScrollToTop path="Quienes-Somos" element={<QuienesSomos />}/>
              <Route ScrollToTop path="Emprende" element={<Emprende />} />
              <Route ScrollToTop path="Empresas" element={<Empresas />} />
              <Route ScrollToTop path="TecnologiaAplicada" element={<TecnologiaAplicada />}/>
              <Route ScrollToTop path="Startup" element={<Startup />} />
              <Route ScrollToTop path="Franquicias" element={<Franquicias />} />
              <Route ScrollToTop path="Verde" element={<Verde />} />
              <Route ScrollToTop path="Taxi" element={<Taxi />} />
              <Route ScrollToTop path="Microcredito" element={<Microcredito />} />
              <Route ScrollToTop path="Faq" element={<FAQ />} />
              <Route ScrollToTop path="Contacto" element={<Contacto />} />
              <Route ScrollToTop path="CalculadoraEmisiones" element={<Emisiones />} >
                <Route index element={<BienvenidaEmisiones />} />
                <Route ScrollToTop path=":id" element={<CalculadoraEmisiones />} />
              </Route>
            </Route>
            <Route ScrollToTop path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
