import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const Pregunta = ({data}) => {
    const [hidden, setHidden] = useState(false)
    const [clase, setClase] = useState("respuesta preload")
    const activarAnimacion = ( ) => {
        if(hidden) {
            setHidden(!hidden)
            setClase("respuesta hide")
        }else{
            setHidden(!hidden)
            setClase("respuesta show")
        }
    }
    return ( 
       <div key={data.idPregunta} onClick={() => activarAnimacion()} className="pregunta">
        <div className="titulo">
        <h2>{data.pregunta}</h2>
        <FontAwesomeIcon icon={faChevronDown}/>
        </div>
        <div className={clase}>
            <p>{data.respuesta}</p>
        </div>

       </div> 
     );
}
 
export default Pregunta;