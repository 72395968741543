const ElementosGrid = () => {
  return (
    <>
      <div className="elementos-grid" style={{ minHeight: 250 }}>
        <div className="encabezado" style={{ height: 257 }}>
          <div className="titulo">Sujeto de Apoyo</div>
          <div className="container">
            Micro, pequeñas y medianas empresas que tengan un proyecto de
            innovación, que sea un sector estratégico, que demuestre inversión
            privada de terceros o rondas de inversión.
          </div>
        </div>
        <div className="encabezado" style={{ height: 257 }}>
          <div className="titulo">Monto</div>
          <div className="container">Hasta $1'000,000.00</div>
        </div>
        <div className="encabezado" style={{ height: 257 }}>
          <div className="titulo">Tipo de Crédito</div>

          <div className="container">Nota Convertible</div>
        </div>
        <div className="encabezado" style={{ height: 257 }}>
          <div className="titulo">Plazo</div>

          <div className="container">
            3 años con posibilidad de extensión a 5 años.
          </div>
        </div>
        <div className="encabezado" style={{ height: 257 }}>
          <div className="titulo">Tasa</div>
          <div className="container">12% anual fija</div>
        </div>
        <div className="encabezado" style={{ height: 257 }}>
          <div className="titulo">Garantías</div>
          <div className="container">No Aplica</div>
        </div>
      </div>


    </>
  );
};

export default ElementosGrid;
