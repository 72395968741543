//Hecho por Ricardo Corral Sanchez. Github: @ricardocos

import Subasta from "./Subasta";
import subastaDatos from "./PruebaSubastas.json";
//import PopUpNotificaciones from "../PopUp/PopUp";

export default function Subastas() {
  return (
    <main className="subastas">
      
      <div className="contenedor-titulo">
        <h1>Listado de Subastas</h1>
      </div>

      <div className="contenedor-subastas">
        {subastaDatos.subastas.map((subasta) => (
          <Subasta subasta={subasta} />
        ))}
      </div>
    </main>
  );
}
