//import PopUpNotificaciones from "../PopUp/PopUp";
import MainContainer from "./Componentes/MainContainer";


const FAQ = () => {
  return (
    <section className="faq">
      
      <div className="background">

      </div>
        <div className="title">
          <h1>Preguntas Frecuentes</h1>
        </div>
        <MainContainer/>
    </section>
  );
};

export default FAQ;
