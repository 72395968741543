import ModalidadHeader from "../Reutilizables/ModalidadHeader";
import Data from "../../Modalidades.json"
import RequisitosCintillo from "../Reutilizables/RequisitosCintillo";
import RequisitosContainer from "../Reutilizables/RequisitosContainer";
import ElementosGrid from "./ElementosGrid";
import ElementosMobile from "./ElementosMobile";
//import PopUpNotificaciones from "../../Paginas/PopUp/PopUp";
const Startup = () => {
    return ( 
        <section className="modalidad">
          
                  <div className="detalles">
        <img
          className="fondo"
          src="/Assets/modalidad/Background.png"
          alt="waves"
        />
            <ModalidadHeader data={Data.modalidades[3]}/>
            <ElementosMobile/>
            <ElementosGrid/>
            </div>
            
            <RequisitosCintillo
            data={Data.modalidades[3]}
            />
            <RequisitosContainer data={Data.modalidades[3]}/>
            <p style={{textAlign:"center",marginTop:"3rem",marginBottom:"2rem",fontWeight:500}}>*Todos los archivos se reciben únicamente por correo electrónico</p>
        </section>
     );
}
 
export default Startup;