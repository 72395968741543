import { TextField } from "@mui/material";
import { useState } from "react";
import { multiply, round } from "mathjs";
const CalculadoraEmisiones = () => {
  const [cosnumoElectrico, setCosnumoElectrico] = useState(0);
  const [consumoGasolina, setConsumoGasolina] = useState(0);
  const calcularEmisionesElectricas = (consumo) => {
    setCosnumoElectrico((consumo * 0.435) / 1000);
  };
  const calcularEmisionesGasolina = (consumo) => {
    setConsumoGasolina((consumo * 2.39) / 1000);
  };
  function formato(numero) {

    if(String(numero) === "NaN" || parseFloat(numero) === 0){
       return 0}
    // Convertir el número a una cadena de texto y dividirlo en partes enteras y decimales
    var partes = round(numero, 2).toString().split(".");
    // Tomar la parte entera del número
    var parteEntera = partes[0];
    // Tomar la parte decimal del número si existe
    var parteDecimal = partes.length > 1 ? "." + partes[1] : "";

    // Agregar separador de miles a la parte entera utilizando una expresión regular
    parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Devolver el número con separador de miles
    return parteEntera + parteDecimal;
  }
  return (
    <div className="calculadora-emisiones">
      <h2>Ingresa tus emisiones</h2>
      <TextField
        onChange={(e) => calcularEmisionesElectricas(e.target.value)}
        label="Consumo electrico anual en kWh"
        type="number"
      />
      <TextField
        onChange={(e) => calcularEmisionesGasolina(e.target.value)}
        label="Consumo de gasolina anual en litros"
        type="number"
      />

      <div className="subtotal">
        <img src="/Assets/emisiones/electricidad.png" alt="lighting" />
        <div className="resultados">
          <p>Huella de carbono por consumo eléctrico:</p>
          <h4> {formato(cosnumoElectrico)} t/CO₂</h4>
        </div>
      </div>
      <div className="subtotal">
        <img src="/Assets/emisiones/fuego.png" alt="fire" />
        <div className="resultados">
          <p>Huella de carbono por consumo de gasolina:</p>
          <h4> {formato(consumoGasolina)} t/CO₂</h4>
        </div>
      </div>
      <div className="total">

        <h3>Tu huella de carbono total:</h3>
        <p> {formato(cosnumoElectrico + consumoGasolina)} toneladas CO₂</p>
       {(cosnumoElectrico + consumoGasolina) > 0 ?  <span>Se necesitan entre <strong>{formato(round((cosnumoElectrico + consumoGasolina) *1000 / 30,0))}</strong> y <strong>{formato(round( (cosnumoElectrico + consumoGasolina) *1000 / 10,0))}</strong> árboles para absorber esta cantidad de CO2</span> : null}
        <img src="/Assets/emisiones/generadores.png" alt="turbines" />
      </div>
    </div>
  );
};

export default CalculadoraEmisiones;
