import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Contenido from "../../../Contenido.json";
import { faFlag, faLightbulb, faStar } from "@fortawesome/free-solid-svg-icons";
const MisionContainer = () => {
  return (
    <div className="mision-container">
      <div className="container">
        <div className="header-container">
          <FontAwesomeIcon icon={faFlag} className="icono" />
          <h2>Misión</h2>
        </div>

        <p>{Contenido.quienesSomos.mision}</p>
      </div>
      <div className="container">
        <div className="header-container">
          <FontAwesomeIcon icon={faLightbulb} className="icono" />
          <h2>Visión</h2>
        </div>

        <p>{Contenido.quienesSomos.vision}</p>
      </div>
      <div className="container">
        <div className="header-container">
          <FontAwesomeIcon icon={faStar} className="icono" />
          <h2>Valores</h2>
        </div>

        <div className="contenedor-valores">
        {Contenido.quienesSomos.valores.map(valor => (
            <p>-{valor}</p>
        ))}
        </div>
      </div>
    </div>
  );
};

export default MisionContainer;
