const ElementosGrid = () => {
  return (
    <>
    <div className='elementos-grid' >
      <div className='encabezado'>
        <div className='titulo'>Sujeto de Apoyo</div>
        <div className='container' >
        Micro, pequeña y mediana empresa, con mínimo 1 año de operación registrada ante el SAT. Con proyecto de tecnología verde.
        </div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Monto</div>
        <div className='container' >Hasta $1'000,000.00</div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Tipo de Crédito</div>

            <div className='container' >Compra de activos fijos (Refaccionario)</div>
          

   
      </div>
      <div className='encabezado'>
        <div className='titulo'>Plazo</div>

            <div className='container' >Hasta 96 meses</div>
         

     
      </div>
      <div className='encabezado'>
        <div className='titulo'>Tasa</div>
        <div className='container' >10% anual fija</div>
      </div>
      <div className='encabezado'>
        <div className='titulo'>Garantías</div>
        <div className='container' >Prendaria, Hipotecaria</div>
      </div>
    </div>

    <h4 className="consideraciones">*Hasta 6 meses de gracia sobre capital en créditos refaccionarios</h4>
    </>
  );
};

export default ElementosGrid;
