import { createContext } from 'react';
import Data from '../DataFAQ.json';
import { useState } from 'react';
const FaqContext = createContext();

const FaqProvider = ({ children }) => {
  const [conceptoSeleccionado, setConceptoSeleccionado] = useState(
    Data.conceptos[0]
  );
  return (
    <FaqContext.Provider
      value={{ conceptoSeleccionado, setConceptoSeleccionado }}
    >
      {children}
    </FaqContext.Provider>
  );
};

export { FaqProvider };

export default FaqContext;
