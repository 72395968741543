import { useState, useRef, useEffect } from "react";

const Licitacion = ({ licitacion }) => {
  const ref = useRef(null);
  const [detalles, setDetalles] = useState(false);

  const mostrarLicitacion = () => {
    if (detalles) {
      ref.current.style.maxHeight = "500px";
    } else {
      ref.current.style.maxHeight = "0px";
    }
  };
  useEffect(() => {
    mostrarLicitacion();
  }, [detalles]);

  return (
    <div className="contenedor-licitacion">
      <div onClick={() => setDetalles(!detalles)} className="titulo-licitacion">
        <h2>{licitacion.licitacion}</h2>
        <h2>Ver documentos</h2>
      </div>
      <div ref={ref} className="licitacion">
        <div className="elemento descripcion">
          <h3>Licitación Pública {licitacion.licitacion}</h3>
          <h4>{licitacion.descripcion}</h4>
        </div>

        {licitacion.elementos.map((elemento) => (
          <div className="elemento documento">
            <h3>{elemento.titulo}</h3>
            <button onClick={() => window.open(elemento.link, "_blank")}>
              Descargar
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Licitacion;
