import Buscador from "./Buscador/Buscador";
import CardGrid from "./CardGrid/CardGrid";
import { DirectorioProvider } from "./Context/DirectorioContext";
import Contenido from "../../Contenido.json";
//import PopUpNotificaciones from "../PopUp/PopUp";
const Directorio = () => {
  function mezclarLista(lista) {
    for (let i = lista.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [lista[i], lista[j]] = [lista[j], lista[i]];
    }
    return lista;
  }

  return (
    <DirectorioProvider>
      <section className="directorio">
      
        <div className="background"></div>
        <section className="title-container">
          <h1>Directorio de Servidores Públicos</h1>
        </section>
        <Buscador />

        <CardGrid
          data={mezclarLista(Contenido.directorio.empleados)}
        />
      </section>
    </DirectorioProvider>
  );
};

export default Directorio;
