import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
const ModalAvisoPrivacidad = ({setModal}) => {
  return (
    <section onClick={() => setModal(false)} className="modal">
      <div className="contenedor-principal">
        <div className="contenedor-titulo">
          <h1>Aviso de Privacidad.</h1>
          <FontAwesomeIcon  className="icono" icon={faXmarkCircle} />
        </div>
            <div className="contenedor-texto">
            <p>
          En cumplimiento a lo dispuesto en la Ley de Protección de Datos
          Personales del Estado de Chihuahua, ponemos a su disposición el
          presente Aviso de Privacidad, que tiene como objeto informarle sobre
          el tratamiento que se le dará a sus datos personales cuando los mismos
          sean recabados, utilizados, almacenados y/o transferidos por el
          Fideicomiso Estatal para el Fomento de las Actividades Productivas en
          el Estado de Chihuahua, cuyas oficinas de apoyo se encuentran ubicadas
          en Paseos Vista del Sol 6601-Local 6 Col.Haciendas C.P. 31215
          Chihuahua, Chihuahua.
        </p>
        <p>
          El Fideicomiso Estatal para el Fomento de las Actividades Productivas
          en el Estado de Chihuahua, se compromete a observar y respetar los
          principios de licitud, finalidad, lealtad, consentimiento, calidad,
          proporcionalidad, información y responsabilidad, que establece la Ley
          de Protección de Datos Personales del Estado de Chihuahua.{" "}
        </p>
        <p>
          Las finalidades que tiene como objeto el tratamiento de Datos
          Personales que realiza el Fideicomiso Estatal para el Fomento de las
          Actividades Productivas en el Estado de Chihuahua y sus encargados se
          orienta necesariamente a:<br></br>
          <ul>
            <li>
              Recabar datos personales que faciliten los trámites que realiza
              departamento de promoción para posteriormente se puedan otorgar
              los créditos que ofrece el Fideicomiso
            </li>
            <li>
              Establecer un contacto directo con el titular para de esta manera
              informar sobre el desarrollo del procedimiento de otorgamiento de
              créditos.
            </li>
            <li>
              Analizar estados de cuenta para verificar los balances con
              relaciones analíticas y verificar la solvencia del titular.
            </li>
            <li>
              Reunir los datos para la celebración de contratos y convenios con
              programas de Promoción al Fomento de Actividades Productivas.
            </li>
            <li> Recabar datos para la contratación de Proveedores</li>
            <li>
              En cuanto a los trámites del personal, En relación al personal que
              labora en el Fideicomiso recabar datos para transferir sus
              documentos al ICHISAL y poder realizar el alta del servicio
              médico, así como instrumentar su expediente administrativo y
              laboral, y registrar expedientes en el sistema CONPAQi
            </li>
          </ul>
        </p>

        <p>
          Es por ello que para llevar a cabo las finalidades descritas en el
          presente aviso de privacidad, el Fideicomiso Estatal para el Fomento
          de las Actividades Productivas en el Estado de Chihuahua, puede
          requerir los siguientes datos personales:
          <ul>
            <li> Nombre completo </li>
            <li> Domicilio fiscal</li>
            <li> Nacionalidad</li>
            <li> Estado civil</li>
            <li> Domicilio particular</li>
            <li> Declaraciones de impuestos </li>
            <li> Reporte de buro de crédito </li>
            <li> Credencial de elector</li>
            <li> CURP</li>
            <li> Teléfono personal</li>
            <li> Teléfono deudor solidario </li>
            <li> Correo electrónico fiscal </li>
            <li> Correo electrónico particular </li>
            <li>
              
              Copia de escritura con certificado de libertad de gravamen{" "}
            </li>
            <li> Factura de Automóvil</li>
            <li> Comprobante de domicilio </li>
            <li> Registro Federal de Contribuyentes (RFC)</li>
            <li> Ingresos mensuales</li>
          </ul>
        </p>
        <p>Los datos personales que anteriormente se mencionan, podrán ser transferidos a diversas dependencias como lo son: la notaría pública que se asigne para formalizar trámites, la Auditoria Superior del Estado, cuando así nos lo requiera para cumplir con sus funciones de vigilancia y fiscalización y respecto al personal, al ICHISAL, esto, con motivo de dar cumplimiento eficaz a las finalidades del Fideicomiso.</p>

        <p>Es por ello que con fundamento en los artículos 14, 63, 64 y 65, y con apego a lo dispuesto por la Ley de Protección de Datos Personales del Estado de Chihuahua, se emite el presente aviso de privacidad, con la firme finalidad de establecer una seguridad al titular sobre los datos personales que nos otorga. Todos los Datos Personales proporcionados a El Fideicomiso Estatal para el Fomento de las Actividades Productivas en el Estado de Chihuahua, serán considerados como información confidencial, obligación que subsistirá a pesar de que el titular de dicha información haya finalizado su relación con el fideicomiso, asimismo dichos Datos Personales no serán divulgados a terceras personas sin la autorización de su titular.</p>

        <h2>Derechos ARCO</h2>
        <p>En relación al tratamiento de los datos personales, una vez concluida la vigencia se estará a los dispuesto por la  Ley de Protección de Datos Personales del Estado de Chihuahua, es por ello que con fundamento en el numeral 31 de dicha ley, usted tiene derecho a conocer qué datos personales se le recabaran, para qué se utilizan y las condiciones del uso que les damos a los mismos (Acceso). Asimismo, es su derecho solicitar la corrección de su información, personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la ley (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO. </p>
        <p>Los derechos ARCO se podrán ejercer directamente en la unidad de transparencia de este instituto, ubicada en Paseos Vista del Sol 6601-Local 6 Col.Haciendas C.P. 31215
          Chihuahua, Chihuahua, o bien, a través del sistema infomex en https://transparenciachihuahua.org/infomex, o al siguiente correo electrónico transparencia@fideapech.com</p>
        <p>El Fideicomiso Estatal para el Fomento de las Actividades Productivas en el Estado de Chihuahua se reserva el derecho de enmendar o modificar el presente Aviso de Privacidad cuando estime conveniente, por ejemplo, para cumplir con cambios a la legislación sobre protección de datos o para efecto de cumplir con las disposiciones internas del fideicomiso. El cual le informara y pondrá a su disposición el Aviso de Privacidad actualizado por medio de www.fideapech.com</p>
        <p>Le informamos que previamente a que recabemos sus datos de identificación, contacto o patrimoniales, le solicitaremos nos otorgue su consentimiento expreso y por escrito y además de conocer del contenido del presente aviso de privacidad.</p>
     
            </div>
        </div>
    </section>
  );
};

export default ModalAvisoPrivacidad;
