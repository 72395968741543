//Hecho por Ricardo Corral Sanchez. Github: @ricardocos

//import PopUpNotificaciones from "../PopUp/PopUp";

const Organigrama = () => {
  return (
    <main className="organigrama">
      
      <div className="organigrama-titulo">
        <h1>Organigrama de comité de adquisiciones</h1>
      </div>

      <div className="contenedor-organigrama">
        <h3 className="integrante">Presidente (a)</h3>
        <h3 className="descripcion">
          Titular del area administrativa del fideapech
        </h3>
        <h3 className="integrante">Vocal 1</h3>
        <h3 className="descripcion">Titular del área requiriente</h3>
        <h3 className="integrante">Vocal 2</h3>
        <h3 className="descripcion">Titular del departamento jurídico</h3>
        <h3 className="integrante">Secretaría Técnica</h3>
        <h3 className="descripcion">Supervisor (a) de recursos materiales</h3>
        <h3 className="integrante">Observadores (as)</h3>
        <h3 className="descripcion">Supervisor (a) de recursos materiales</h3>
        <h3 className="integrante">Invitados (as)</h3>
        <h3 className="descripcion">
          Quienes deben aclarar aspectos técnicos, administrativos o de
          cualquier otra naturaleza, de su competencia, relacionados
          exclusivamente con el asunto para el cual hubieran sido invitados
        </h3>
        <h3 className="integrante fondo">Testigos sociales</h3>
        <h3 className="descripcion fondo">
          Del padrón público de testigos sociales de la Función Pública.{" "}
        </h3>
      </div>
    </main>
  );
};

export default Organigrama;
