import { useState } from 'react';
import Modal from './Modal';
const Requisito = ({requisito,numero}) => {
  const [descripcion, setDescripcion] = useState(false);
  const [modal, setModal] = useState(false)
  const abrirDescripcion = () => {
    if(requisito.modal){
      setModal(!modal)
    }else{
      setDescripcion(!descripcion)
    }
  }
  return (
    <div onClick={abrirDescripcion} className={descripcion ? 'requisito mostrar-requisito' : 'requisito ocultar-requisito'}>
      <div className='numeral'>
        <div className='numero'>{Number(numero) + 1}</div>
        <hr />
      </div>
      <div className='texto'>
        <div className='titulo'>{requisito.titulo}</div>
  
          <div className='descripcion'>
            {requisito.descripcion}
          </div>
       
      </div>
      {modal ? <Modal setModal={abrirDescripcion}/> : null}
    
    </div>
  );
};

export default Requisito;
