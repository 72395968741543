import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from "@fortawesome/free-regular-svg-icons";
const EmptyCard = () => {
    return ( 
        <div className="empty-card">
            <FontAwesomeIcon className="icon" icon={faFaceFrown}/>
            <h1>No tenemos registro de la persona que buscas </h1>
        </div>
     );
}
 
export default EmptyCard;